// WordPress API configuration

// Use the environment variable for the WordPress API URL
export const WORDPRESS_API_URL = import.meta.env.VITE_WORDPRESS_API_URL;

// Log a warning if the environment variable is not set
if (!WORDPRESS_API_URL && import.meta.env.DEV) {
  console.warn('VITE_WORDPRESS_API_URL is not defined in your environment. WordPress features might not work correctly.');
}

// Assume WordPress is always enabled in production.
// It's enabled in development if the API URL is set.
// It might be disabled in specific Netlify contexts (like previews) ONLY if VITE_WORDPRESS_API_URL is NOT set for that context.
const isPreviewWithoutApiUrl =
  (import.meta.env.MODE === 'preview' || window.location.hostname.includes('netlify.app')) && 
  !import.meta.env.VITE_WORDPRESS_API_URL;

// Simplified logic: Enabled unless it's a preview deployment *without* an explicit API URL.
export const WORDPRESS_ENABLED = !isPreviewWithoutApiUrl;

export const ENDPOINTS = {
  POSTS: '/posts',
  PAGES: '/pages',
  MEDIA: '/media',
  CATEGORIES: '/categories',
  TAGS: '/tags',
  USERS: '/users',
  SEARCH: '/search',
} as const;

export const PER_PAGE = 10;

export const ERROR_MESSAGES = {
  API_NOT_ACCESSIBLE: 'WordPress API is not accessible',
  POSTS_FETCH_ERROR: 'Failed to fetch posts',
  POST_FETCH_ERROR: 'Failed to fetch post',
  PAGE_FETCH_ERROR: 'Failed to fetch page',
  MEDIA_FETCH_ERROR: 'Failed to fetch media',
  CATEGORIES_FETCH_ERROR: 'Failed to fetch categories',
  TAGS_FETCH_ERROR: 'Failed to fetch tags',
  USERS_FETCH_ERROR: 'Failed to fetch users',
  SEARCH_ERROR: 'Failed to perform search',
} as const;